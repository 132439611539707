<template>
  <div class="stats">
    <n-loader :loading="$var('load')" />
    <div class="container">
      <div class="wrap-title">
        <div class="title">Статистика</div>
      </div>
      <div class="wrap-subtitle">
        <div class="title">На данный момент</div>
      </div>
      <div class="piles-wrapper">
        <div v-for="item in branches" :key="item.id" class="pile-wrapper">
          <div class="pile" @click="$router.push({name: 'statsDepartments', params: {id: item.id}})">
            <div class="count">{{ stats[item.id]?.length || 0 }}</div>
            <div class="subtitle">сотрудника/ов</div>
          </div>
          <div class="title">{{ item.title }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PageStats',
  data() {
    return {
      user: $app.auth.user(),
      stats: [],
      branches: [],
    }
  },
  created() {
    this.loadStats()
  },
  methods: {
    loadStats() {
      this.$var('load', true)
      $api.company.getActivePeople(this.user.companyId, {
        groupBy: 'branches',
      }).then((response) => {
        this.stats = response.data.content
        this.loadBranches()
      }).catch(() => {
        this.$var('load', false)
      })
    },
    loadBranches() {
      $api.company.getBranches(this.user.companyId).then((response) => {
        this.branches = response.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style scoped lang="scss">
.stats {
  display: flex;
  flex-direction: column;
  padding-top: 29px;
  .wrap-title{
    text-align: center;
    position: fixed;
    background-color: #F5F5FA;
    left: 0;
    right: 0;
    top: calc( env(safe-area-inset-top) + 100px);
  }
  .wrap-subtitle {
    margin-top: 20px;
  }
  .title {
    color: black;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
  }
  .navigation {
    margin-top: 20px;
    .item {
      .left-side {
        display: flex;
        align-items: center;
        font-weight: bold;
        .n-icon {
          margin-right: 10px;
        }
      }
    }
  }

  .piles-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex-shrink: 0;
    .pile-wrapper {
      margin-top: 20px;
      margin-right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .pile {
        width: 100px;
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: #8B6DEC;
        border-radius: 10px;
        padding: 5px 10px;
        .count {
          color: #FFFFFF;
          font-size: 22px;
          font-weight: 700;
        }
        .subtitle {
          color: #FFFFFF;
          font-weight: 400;
          font-size: 10px;
        }
      }
      .title {
        font-size: 12px;
        font-weight: 400;
      }
    }
  }
}
</style>
